<template>
  <div class="custom-modal-warp">
    <a-modal v-bind="getFormProps" centered :transition-name="''" :width="width"
      class="custom-modal-container">
      <template #title v-if="$slots['modal-title']">
        <slot name="modal-title"></slot>
      </template>
      <template v-if="$slots['modal-content']">
        <slot name="modal-content"></slot>
      </template>
      <template v-if="$slots['operate-button']">
        <slot name="operate-button"></slot>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  watchEffect
} from 'vue';
import modal from 'ant-design-vue/lib/modal';
export default defineComponent({
  name: 'CustomModal',
  props: {
    ...modal.props,
    headerStyle: Object
  },
  emits: ['submit', 'reset'],
  setup (props, { attrs, emit }) {
    const getFormProps = computed(() => {
      return {
        ...attrs,
        ...props,
      };
    });

    return {
      getFormProps
    }
  }

});
</script>

<style lang="less" scoped>
.custom-modal-warp {
  .custom-modal-container {
    :global(.ant-modal-header) {
      border: none;
      padding: 24px 24px 0 24px;
    }


  }

  :global(.custom-modal-container .ant-modal-body) {
    padding: 0 24px 24px 24px;
  }

}
</style>